<template>
    <div>
        <div class="page">
            <div class="page_operate">
                <div class="page_handleBtn">
                    <!-- <button class="button" @click="addFatherRow">添加一级分类</button> -->
                    <VyAddBtn @click.native="addFatherRow" />
                </div>
            </div>

            <div class="page_content">
                <el-table
                    ref="table"
                    border
                    :indent="30"
                    :data="tableData"
                    style="width: 100%; margin-bottom: 20px"
                    :row-key="getRowKey"
                    @row-click="handleRowClick"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                >
                    <el-table-column align="center" label="分类名称">
                        <template slot-scope="scope">
                            <input
                                class="content_value"
                                type="text"
                                v-if="!scope.row.editing"
                                v-model="scope.row.category_title"
                                placeholder="请输入名称"
                                @click.stop
                            />
                            <span v-else>
                                {{ scope.row.category_title }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="规格图定位">
                        <template slot-scope="scope" v-if="scope.row.pid == 0">
                            <div v-if="!scope.row.editing">
                                <el-switch
                                    v-model="scope.row.size_position"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="'right'"
                                    :inactive-value="'left'"
                                    active-text="右"
                                    inactive-text="左"
                                >
                                </el-switch>
                            </div>
                            <div v-else>
                                {{ scope.row.size_position == "right" ? "右" : "左" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="前端排序">
                        <template slot-scope="scope">
                            <el-input-number
                                size="mini"
                                v-if="!scope.row.editing"
                                v-model="scope.row.sort"
                                :min="0"
                                :max="9999"
                            ></el-input-number>
                            <div v-else>
                                {{ scope.row.sort }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态">
                        <template slot-scope="scope">
                            <div v-if="!scope.row.editing">
                                <el-switch
                                    v-model="scope.row.status"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="显示"
                                    inactive-text="隐藏"
                                >
                                </el-switch>
                            </div>
                            <div v-else class="content_status" :class="scope.row.status ? 'success_color' : 'danger_color'">
                                {{ scope.row.status ? "显示" : "隐藏" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="置顶">
                        <template slot-scope="scope">
                            <div v-if="!scope.row.editing">
                                <el-switch
                                    v-model="scope.row.is_top"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="是"
                                    inactive-text="否"
                                >
                                </el-switch>
                            </div>
                            <div v-else>
                                <div class="content_isTop" :class="scope.row.is_top ? 'success_color' : 'danger_color'">
                                    <span>{{ scope.row.is_top ? "是" : "否" }}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="使用情况">
                        <template slot-scope="scope">
                            <div
                                v-if="!scope.row.children"
                                class="content_isTop"
                                :class="scope.row.is_use ? 'success_color' : 'danger_color'"
                            >
                                <span>{{ scope.row.is_use ? "使用中" : "未使用" }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="220">
                        <template slot-scope="scope">
                            <div class="content_operate" v-if="!scope.row.editing">
                                <button class="operate_button3" @click.stop="finishFatherData(scope.row)">完成</button>
                                <button class="operate_button2" @click.stop="handleCancel(scope.row)">取消</button>
                            </div>
                            <div v-else>
                                <div class="content_operate">
                                    <button class="operate_button1" @click.stop="updataData(scope.row)">编辑</button>
                                    <!-- 父集删除 -->
                                    <button
                                        v-if="scope.row.children"
                                        :class="scope.row.children.length > 0 ? 'operate_button_disable' : 'operate_button2'"
                                        :disable="scope.row.children.length > 0"
                                        @click.stop="deleteInfoById(scope.row, 'father')"
                                    >
                                        删除
                                    </button>
                                    <!-- 子集删除 -->
                                    <button
                                        v-else
                                        :class="scope.row.is_use ? 'operate_button_disable' : 'operate_button2'"
                                        :disable="scope.row.is_use"
                                        @click.stop="deleteInfoById(scope.row, 'son')"
                                    >
                                        删除
                                    </button>
                                </div>
                                <div class="content_operate" v-if="scope.row.children">
                                    <button class="operate_button3" @click="addChildren(scope.row)">添加子集</button>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog
            width="60%"
            :title="'添加' + childDialogTitle + '子集'"
            style="margin-right: 10% !important; margin-top: 5% !important"
            :visible.sync="innerVisible"
            :before-close="bfc"
            append-to-body
        >
            <div class="dialog_content">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">分类名</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.category_title" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">状态</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-switch
                                    style="display: block"
                                    v-model="form.status"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="显示"
                                    inactive-text="隐藏"
                                >
                                </el-switch>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">置顶</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-switch
                                    style="display: block"
                                    v-model="form.is_top"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="是"
                                    inactive-text="否"
                                >
                                </el-switch>
                            </div>
                        </template>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog_handle">
                <button class="button" @click="addChildrenData">添加</button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";
export default {
    components: { VyAddBtn },
    data() {
        return {
            tableData: [],

            // 判断是否为添加数据
            isAddOrAmend: 0,

            classificationName: "",

            // 内层dialog
            childDialogTitle: "",
            innerVisible: false,
            form: {},
            // 选择的二级分类的id
            category_id: "",
        };
    },
    mounted() {
        this.getPageInfo();
    },
    methods: {
        // 获取全部数据
        getPageInfo() {
            axios.post("/api/Project/category").then(res => {
                this.tableData = res.data;
            });
        },

        // 表格 -- 选中行数据
        handleCurrentChange(currentRow, oldCurrentRow = {}) {
            if (currentRow.id != oldCurrentRow.id && oldCurrentRow.editing == 0) {
                if (!oldCurrentRow.id) return;
                this.$confirm("是否确认保存正在编辑的行", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                    showClose: false,
                })
                    .then(() => {
                        this.finishFatherData(oldCurrentRow);
                    })
                    .catch(() => {
                        this.getPageInfo();
                    });
            }
        },
        // 添加分类 -- 添加一行父集数据
        addFatherRow() {
            this.tableData.unshift({
                category_title: "",
                sort: 0,
                children: [],
                editing: 0,
                pid: 0,
                status: 1,
            });
            this.isAddOrAmend = "add";
        },
        // 取消按钮
        handleCancel(row) {
            if (!row.id) {
                this.tableData.shift();
            }
            this.getPageInfo();
        },
        // 编辑
        updataData(row) {
            this.$refs.table.setCurrentRow(row);
            row.editing = 0;
            this.isAddOrAmend = "amend";
        },
        // 添加子集数据
        addChildren(row) {
            this.form = {
                pid: row.id,
                status: 1,
                category_title: "",
                is_top: 0,
            };
            this.innerVisible = true;
            this.childDialogTitle = row.category_title;
        },
        // 子集数据提交
        addChildrenData() {
            axios.post("/api/project_category/sadd ", qs.stringify(this.form)).then(res => {
                if (res.data.status_code == "error") {
                    this.$message.error(res.data.msg);
                } else {
                    this.$message({
                        type: "success",
                        message: "添加成功",
                    });
                    this.innerVisible = false;
                    this.getPageInfo();
                    this.form = {};
                }
            });
        },
        // 完成
        finishFatherData(row) {
            if (this.isAddOrAmend == "add") {
                if (row.sort == "") {
                    row.sort = 0;
                }
                if (row.category_title == "") {
                    this.$message.error("分类名称不能为空");
                } else {
                    row.editing = 1;
                    row.pid = 0;
                    // return console.log(row);
                    axios.post("/api/project_category/fadd", qs.stringify(row)).then(() => {
                        this.$message({
                            type: "success",
                            message: "添加成功",
                        });
                        this.getPageInfo();
                    });
                }
            } else if (this.isAddOrAmend == "amend") {
                if (row.category_title == "") {
                    this.$message.error("分类名称不能为空");
                } else {
                    if (row.pid != 0) {
                        // 修改二级
                        axios.put("/api/project_category/supdate", qs.stringify(row)).then(() => {
                            this.$message({
                                type: "success",
                                message: "修改成功",
                            });
                            this.getPageInfo();
                        });
                        return;
                    }
                    row.editing = 1;
                    delete row.children;
                    delete row.lenght;
                    axios
                        .post(
                            "/api/project_category/edit_categorys",
                            qs.stringify({
                                ...row,
                            })
                        )
                        .then(() => {
                            this.$message({
                                type: "success",
                                message: "修改成功",
                            });
                            this.getPageInfo();
                        });
                }
            }
        },

        // 表格子集的key值
        getRowKey(row) {
            return row.id;
        },
        // 表格 -- 点击行打开子集
        handleRowClick(row, column, event) {
            // if (row.pid == 0 && event.target.tagName == "TD") {
            // console.log(event, row);
            if (event.target.tagName == "BUTTON" || !row.editing) {
                return;
            }
            this.$refs.table.toggleRowExpansion(row);
            // }
        },

        // 删除
        deleteInfoById(row, value) {
            if (value == "father") {
                if (row.children.length > 0) {
                    this.$message({
                        type: "info",
                        message: "请确保该分类下并无子集!",
                    });
                } else {
                    this.$confirm("是否删除?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            axios
                                .post(
                                    "/api/Project_category/catedel",
                                    qs.stringify({
                                        id: row.id,
                                    })
                                )
                                .then(({ data }) => {
                                    this.$message({
                                        type: data.code == 200 ? "success" : "error",
                                        message: data.msg,
                                    });
                                    this.getPageInfo();
                                });
                        })
                        .catch(() => {});
                }
            } else {
                if (row.is_use) {
                    this.$message({
                        type: "info",
                        message: "该分类正在使用中!",
                    });
                } else {
                    this.$confirm("是否删除?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            axios
                                .post(
                                    "/api/Project_category/catdel",
                                    qs.stringify({
                                        id: row.id,
                                    })
                                )
                                .then(({ data }) => {
                                    this.$message({
                                        type: data.code == 200 ? "success" : "error",
                                        message: data.msg,
                                    });
                                    this.getPageInfo();
                                });
                        })
                        .catch(() => {});
                }
            }
        },
    },
};
</script>
<style scoped lang="less">
.page {
    .page_operate {
        margin: 20px 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .page_search {
            display: flex;
            align-items: center;

            div:nth-child(1) {
                margin-right: 5px;
            }

            div:nth-child(2) {
                margin-right: 10px;
            }

            /deep/ .el-input__inner {
                border-radius: 20px;
            }

            input {
                height: 40px;
                padding-left: 15px;
                border: 1px solid #ebedf2;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 20px;

                &:focus {
                    outline: 1px solid skyblue;
                }
            }
        }
    }
    .page_content {
        margin-top: 10px;

        .el-table {
            border-radius: 20px;
        }

        .content_img {
            width: 120px;
            height: 100px;
            object-fit: cover;
        }

        .content_value {
            width: 130px;
            height: 43px;
            border-radius: 13px;
            border: 1px solid #dcdfe6;
            text-align: center;
        }

        .content_status,
        .content_isTop {
            width: 54px;
            height: 33px;
            line-height: 33px;
            color: #fff;
            margin: 0 auto;
            border-radius: 20px;
        }

        .content_operate {
            display: flex;
            justify-content: space-around;
            align-items: center;

            button {
                width: 92px;
                height: 36px;
                border-radius: 16px;
                border: 0;
                font-size: 14px;
                letter-spacing: 2px;
                color: #fff;
                margin: 5px 0;
                cursor: pointer;
            }

            .operate_button1 {
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }

            .operate_button2 {
                background: black;
            }

            .operate_button3 {
                // background: linear-gradient(to right, #84d9d2, #07cdae);
                background: linear-gradient(to right, #f38ca8, #fe7096);
            }
            .operate_button_disable {
                cursor: not-allowed;
                background: #ccc;
            }
        }
    }
}

.dialog_content {
    width: 100%;
    padding: 10px;
    .form_label {
        width: 80px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        color: white;
        font-size: 16px;
        border: 0;
        border-radius: 20px 0 0 20px;
        background: linear-gradient(to right, #da8cff, #9a55ff);
    }

    .form_value {
        .el-switch {
            // width: 30%;
            height: 43px;
            line-height: 43px;
            margin-left: 16px;
        }
        /deep/ .el-switch__label {
            height: 43px !important;
        }

        .form_input {
            height: 43px;
            width: 100%;
            padding-left: 15px;
            border: 1px solid #ebedf2;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 0 13px 13px 0;

            &:focus {
                outline: 1px solid skyblue;
            }
        }
    }
}

.dialog_handle {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        margin-right: 10px;
    }
}

/deep/ .el-dialog {
    margin-right: 100px !important;
    margin-top: 100px !important;
}

/deep/ .el-dialog__body {
    padding: 0 20px !important;
}
</style>
